import React, { FunctionComponent } from 'react'
import ProjectMauticTrialForm from 'Root/EasyProjectGatsby/src/components/MauticForms/MauticTrialForm'
import RedmineMauticTrialForm from 'Root/EasyRedmineGatsby/src/components/MauticForms/MauticTrialForm'

import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareSideCardNewsletterType {
  contentType: 'EasySoftwareSideCardNewsletter'
  displayText: string
  contentItemId: string
  sideCardNewsletterText: PuxWysiwygHtmlBodyType
}

import './EasySoftwareSideCard.scss'

const rootClass = `sidebar`

const EasySoftwareSideCardNewsletter: FunctionComponent<EasySoftwareSideCardNewsletterType> = (
  props
) => {
  return (
    <div className={`${rootClass}-box`}>
      <h4 className={`${rootClass}-box-title`}>{props.displayText}</h4>
      <PuxWysiwyg
        content={props.sideCardNewsletterText}
        className={`${rootClass}-box-perex perex`}
      />
      { process.env.PROJECT === `EasyRedmineGatsby` ? <RedmineMauticTrialForm /> : <ProjectMauticTrialForm />}
    </div>
  )
}

export default EasySoftwareSideCardNewsletter
